// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--2164e";
export var cardBg = "AngularTechStacks-module--cardBg--1b88f";
export var cards = "AngularTechStacks-module--cards--fd951";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--d4e1f";
export var description = "AngularTechStacks-module--description--05b61";
export var heading = "AngularTechStacks-module--heading--95266";
export var iconssCard = "AngularTechStacks-module--iconssCard--aa8ce";
export var nav = "AngularTechStacks-module--nav--491bc";
export var navItem = "AngularTechStacks-module--nav-item--2a8c4";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--7b7eb";
export var tabData = "AngularTechStacks-module--tabData--4402d";
export var tech = "AngularTechStacks-module--tech--208d0";
export var techIcon = "AngularTechStacks-module--techIcon--526d4";
export var techImg = "AngularTechStacks-module--techImg--27426";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--bfe2c";