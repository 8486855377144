// extracted by mini-css-extract-plugin
export var SliderWidth = "Success-module--SliderWidth--b8741";
export var SuccessWebUSA = "Success-module--SuccessWebUSA--b098b";
export var arrowImg = "Success-module--arrowImg--fbbb8";
export var bottomOfCard = "Success-module--bottomOfCard--5aaaa";
export var card = "Success-module--card--139e7";
export var cardWrapper = "Success-module--cardWrapper--e7282";
export var cards = "Success-module--cards--2905a";
export var clientData = "Success-module--clientData--f1a1a";
export var clientDec = "Success-module--clientDec--93799";
export var clientImg = "Success-module--clientImg--73a27";
export var clientName = "Success-module--clientName--80e36";
export var clientRating = "Success-module--clientRating--11d6f";
export var clientsData = "Success-module--clientsData--bfdba";
export var description = "Success-module--description--cfb2b";
export var heading = "Success-module--heading--dc607";
export var headingPremium = "Success-module--headingPremium--4e51d";
export var iconContainer = "Success-module--iconContainer--adbf7";
export var iconContainerLeft = "Success-module--iconContainerLeft--de67a";
export var imgTag = "Success-module--imgTag--dae83";
export var innerCardReview = "Success-module--innerCardReview--7436b";
export var portfolioArrowIcon = "Success-module--portfolioArrowIcon--58c96";
export var portfolioArrowIconCover = "Success-module--portfolioArrowIconCover--72c5a";
export var portfolioArrowRightIconCover = "Success-module--portfolioArrowRightIconCover--aa186";
export var providingImg = "Success-module--providingImg--2a605";
export var trailBg = "Success-module--trailBg--0e168";