// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--fa0ef";
export var cardBg = "AngularTechStacksMobile-module--cardBg--03ba3";
export var cards = "AngularTechStacksMobile-module--cards--aafaf";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--fa696";
export var description = "AngularTechStacksMobile-module--description--2c009";
export var heading = "AngularTechStacksMobile-module--heading--46ae3";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--00563";
export var nav = "AngularTechStacksMobile-module--nav--2592d";
export var navItem = "AngularTechStacksMobile-module--nav-item--8ffd9";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--eab33";
export var tabData = "AngularTechStacksMobile-module--tabData--663fa";
export var tech = "AngularTechStacksMobile-module--tech--da33f";
export var techIcon = "AngularTechStacksMobile-module--techIcon--bdb36";
export var techImg = "AngularTechStacksMobile-module--techImg--e94c4";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--b3f4c";