import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./AngularTechStacksMobile.scss"
import * as styles from "./AngularTechStacksMobile.module.scss"

const AngularTechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Frontend",
      tech: [
        {
          name: "Javascript",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_51cf86eab4.svg",
        },
        {
          name: "React/Redux",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_bbfc441a92.svg",
        },
        {
          name: "jQuery",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jquery_b557ab6f1b.svg",
        },
        {
          name: "Ajax",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ajax_d36bd13135.svg",
        },
        {
          name: "HTML5",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_46c7741ee6.svg",
        },
        {
          name: "CSS3/Sass",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_729ce9077e.svg",
        },
      ],
    },
    {
      title: "Backend",
      tech: [
        {
          name: "PHP",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_bf41aadb66.svg",
        },
        {
          name: "Drupal",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/drupal_d4dd056d30.svg",
        },
        {
          name: "Twig",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/twig_65ea048246.svg",
        },
      ],
    },
    {
      title: "Frameworks",
      tech: [
        {
          name: "Node.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_700ce8f497.svg",
        },
        {
          name: "Express.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/express_js_d88d76c098.svg",
        },
        {
          name: "Angular.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_js_a86e6e3ad5.svg",
        },
        {
          name: "Vue.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_0e0a8fb370.svg",
        },
        {
          name: "Backbone.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/backbone_js_3ce25a6134.svg",
        },
        {
          name: "Ember.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ember_js_2f3887643b.svg",
        },
        {
          name: "React.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_1154471199.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bootstrap_0859e29ba9.svg",
        },
        {
          name: "Material UI",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/material_ui_0162fffcb0.svg",
        },
      ],
    },
    {
      title: "Databases",
      tech: [
        {
          name: "MongoDB",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_b915357e37.svg",
        },
        {
          name: "PostgreSQL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_e2a729b197.svg",
        },
        {
          name: "SQLite",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_4b116a98e6.svg",
        },
        {
          name: "MariaDB",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mariadb_afe2ca1861.svg",
        },
        {
          name: "MS SQL Server",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_sql_server_86d12e890f.svg",
        },
        {
          name: "Redis/Memcache",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_e086fdfea7.svg",
        },
      ],
    },
    {
      title: "APIs",
      tech: [
        {
          name: "Payment Gateways",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/payment_gateways_986cebe4ff.svg",
        },
        {
          name: "Location & Maps",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/location_and_map_32951c51ab.svg",
        },
        {
          name: "Social Signup",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/social_signup_26d57adca3.svg",
        },
        {
          name: "Third-Party",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/third_party_0c27efd9d6.svg",
        },
        {
          name: "SMS/Call",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sms_or_call_36ce962d85.svg",
        },
        {
          name: "AI APIs/SDKs",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_api_s_or_sdk_9d14e5a241.svg",
        },
      ],
    },
    {
      title: "Cloud & DevOps",
      tech: [
        {
          name: "Docker",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/docker_d3365e6dc2.svg",
        },
        {
          name: "Azure",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_3871b2f717.svg",
        },
        {
          name: "Digital Ocean",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_0cb308088c.svg",
        },
        {
          name: "Kubernetes",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_55c12db2f1.svg",
        },
        {
          name: "Google Cloud",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_1e96a7f540.svg",
        },
        {
          name: "Serverless Functions",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/serverless_functions_e60362df63.svg",
        },
        {
          name: "Jenkins",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jenkins_629397be6d.svg",
        },
        {
          name: "AWS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_22f15bb18a.svg",
        },
        {
          name: "Apache",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/apache_1737baa33a.svg",
        },
        {
          name: "PM2 / Supervisor",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pm2_6f84e66e95.svg",
        },
      ],
    },
    {
      title: "Testing",
      tech: [
        {
          name: "Postman",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postman_49dba5f42f.svg",
        },
        {
          name: "Jmeter",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/j_meter_1c7715434f.svg",
        },
        {
          name: "Selenium",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/selenium_d2c2897f14.svg",
        },
        {
          name: "Cypress",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cypress_610c0656c1.svg",
        },
        {
          name: "BrowserStack",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/browser_stack_f2ccdc3180.svg",
        },
        {
          name: "Bugzilla",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bugzilla_080d09df97.svg",
        },
        {
          name: "SoapUI",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/soap_ui_f3840e3086.svg",
        },
        {
          name: "Jasmine",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jasmine_cbcedbeac0.svg",
        },
        {
          name: "Karma",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/karma_f740964098.svg",
        },
        {
          name: "Protractor",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/protractor_31328e3d6d.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="AngularTechMobile">
      <div className={styles.AngularIndusTechHireWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`AngularAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2 || i === 4 || i === 6) &&
                          e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`TypeScriptcardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                    style={{ height: "60px", width: "60px" }}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default AngularTechStacksMobile
