// extracted by mini-css-extract-plugin
export var btn = "Empower-module--btn--31871";
export var cards = "Empower-module--cards--91d2e";
export var description = "Empower-module--description--55272";
export var empowerCyberTypescript = "Empower-module--empowerCyberTypescript--8a724";
export var expertiseProduct = "Empower-module--expertiseProduct--f1c17";
export var hire = "Empower-module--hire--0aa66";
export var hireHeading = "Empower-module--hireHeading--7ee80";
export var hireImg = "Empower-module--hireImg--256bf";
export var mobileImaga = "Empower-module--mobileImaga--dabd3";
export var productHeading = "Empower-module--productHeading--4d927";
export var subTitle = "Empower-module--subTitle--4a626";
export var talent = "Empower-module--talent--bf08c";
export var teamBtn = "Empower-module--teamBtn--72e3c";
export var text = "Empower-module--text--29123";
export var tick = "Empower-module--tick--1f4d1";