import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import * as styles from "./Success.module.scss"

const Success = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: true,
    dots: false,
    slidesToShow: 2,
    arrows: false,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 400,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.SuccessWebUSA}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={` ${styles.cardWrapper}`}>
            <Slider
              {...settings}
              className={`${styles.SliderWidth} `}
              ref={slide1}
            >
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <Col xs={12} key={i}>
                    <div className={styles.card}>
                      <p
                        className={styles.innerCardReview}
                        dangerouslySetInnerHTML={{
                          __html: e?.description.description,
                        }}
                      />
                      <div
                        className={` justify-content-between align-items-center d-flex ${styles.bottomOfCard}`}
                      >
                        <div
                          className={` align-items-center d-flex ${styles.clientsData}`}
                        >
                          <div className={styles.clientImg}>
                            <img
                              src={
                                e?.image1 && e?.image1[0]?.localFile?.publicURL
                              }
                              alt={e?.title}
                              decoding="async"
                              loading="lazy"
                              className={styles.imgTag}
                            />
                          </div>
                          <div className={styles.clientData}>
                            <p className={styles.clientName}>{e?.title}</p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.subTitle,
                              }}
                              className={styles.clientDec}
                            />
                          </div>
                        </div>
                        <div className={styles.clientRating}>
                          <img
                            src="https://invozone-backend.s3.us-east-1.amazonaws.com/5_Stars_f7c42777ee.svg"
                            alt={"rating review"}
                            decoding="async"
                            loading="lazy"
                            style={{
                              height: "24px",
                              width: "128px",
                            }}
                          />
                          <p>5.0 Stars Reviews</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Slider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Success
